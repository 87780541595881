<template>
  <div>
    <UIExit label="EXIT MY SALARY" />

    <TitleWrapper :filter-option="false" title="SLY" tooltip-title="SLY" :display-breadcrumb="true">
      <template v-slot:filterItems>
        <h2 v-i18n="result" class="mb-3 text-text-color font-roboto font-normal text-xl">Search</h2>
      </template>
    </TitleWrapper>
    <div class="minHieght100 main-content bg-primary-white rounded-t-lg mt-5 p-5">
      <template v-if="isLoading"><Loader class="mt-20 mb-40" :content="true" /></template>
      <div v-else-if="!isLoading && noRecord">
        <NoRecordFound />
      </div>
      <TableWrapper v-else>
        <THead>
          <TRHead>
            <TH v-for="(head, index) in tableHead" :key="index">
              <span v-i18n="dashboard">
                {{ head }}
              </span>
            </TH>
          </TRHead>
        </THead>
        <TBody>
          <TRBody v-for="(salary, index) in salaryList" :key="index">
            <TD>
              <span class="text-primary-green cursor-pointer" @click="redirectToDetailPage(salary)">
                {{ salary.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
              </span>
            </TD>
            <TD>
              <UiBadgeDisplay
                :options="[salary.staff_user]"
                :image="true"
                label="full_name"
                class="pl-8"
              />
            </TD>
            <TD>
              {{ salary.base_salary || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </TD>
            <TD>
              {{ salary.gross_salary || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </TD>
            <TD>
              {{ salary.net_salary || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </TD>
          </TRBody>
        </TBody>
      </TableWrapper>
      <Pagination
        v-if="totalSalaryCount > 10"
        v-show="!isLoading"
        :record-limit="filteredRecordLimit"
        :max-range="paginationCounts(totalSalaryCount, filteredRecordLimit)"
        @filterRecord="_getSalries"
      />
    </div>
  </div>
</template>

<script>
import Loader from '@components/BaseComponent/Loader.vue'
import TitleWrapper from '@components/TitleWrapper.vue'
import { mapActions, mapState } from 'vuex'
import generalMixin from '@src/mixins/general-mixins.js'
import Pagination from '@components/BaseComponent/Pagination.vue'
import { getTimeFromDateWithUTC } from '@utils/moment.util'
import TIME_CONSTANTS from '@src/constants/date-time-constants'
import TableAction from '@components/TableAction.vue'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import UiBadgeDisplay from '@src/components/UiElements/UiBadgeDisplay.vue'
import UIExit from '@src/components/UiElements/UIExit.vue'
import scrollMixin from '@src/mixins/scroll-mixin'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
export default {
  components: {
    Loader,
    TitleWrapper,
    Pagination,
    UiBadgeDisplay,
    UIExit,
    NoRecordFound,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [generalMixin, scrollMixin],
  data() {
    return {
      result: 'result',
      title: 'title',
      dashboard: 'dashboard',
      totalSalaryCount: 0,
      tableHead: ['Salary Title', 'Staff', 'Base Salary', 'Gross Salary', 'Net Salary'],
      salaryList: [],
      noRecord: false,
      filteredRecordLimit: 10,
      currentSalaryObject: { staff_user: {}, published_at: null },
      salaryDetails: [],
      GENERAL_CONSTANTS,
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.salary.isLoading,
    }),
  },
  created() {
    this._getSalries()
  },
  methods: {
    redirectToDetailPage(salary) {
      this.$router?.push({
        name: 'my-salary-detail',
        params: {
          id: salary.id,
        },
      })
    },

    async _getSalries(range) {
      const paginationLimit = {
        skip: range?.skip || this.GENERAL_CONSTANTS.RECORD_SKIP,
        limit: range?.limit || this.GENERAL_CONSTANTS.RECORD_LIMIT,
      }
      const [res, err] = await this.getSalaryHistory(paginationLimit)
      this.salaryList = res.data.records.map((rec) => {
        rec.staff_user.full_name = this.getFullName(rec)
        if (rec.extra_working_unit && rec.extra_working_unit_rate)
          rec.extra_working = [
            { amount: rec.extra_working_unit_rate, unit_type: rec.extra_working_unit },
          ]
        return rec
      })
      this.totalSalaryCount = res.data.meta.total_records
    },

    getFullName(salaryObject) {
      return `${salaryObject.staff_user.first_name} ${salaryObject.staff_user.last_name}`
    },

    ...mapActions('salary', ['getSalaryHistory']),
  },
}
</script>

<style lang="scss" module>
.widthItem {
  min-width: 50%;
}
.minHieght80 {
  min-height: 80vh;
}
</style>
d
